import ReactPlayerLoader from "@brightcove/react-player-loader";
import React, { useEffect, useState } from "react";
import useInterval from "../../hooks/useInterval";
import { PlayerWrapper } from "./styled";
import {
  BrightcovePlayer,
  BrightcovePlayerOnSuccessProps,
  BrightcovePlayerProps,
  Event,
} from "./types";

const DEFAULT_PLACEHOLDER =
  "https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/it-modernization-summit/2023/live/placeholders/on_demand.png";

const BrightcoverPlayer = ({
  accountId,
  videoId,
  placeholder = DEFAULT_PLACEHOLDER,
}: BrightcovePlayerProps) => {
  const [player, setPlayer] = useState<BrightcovePlayer>();
  const [error, setError] = useState<unknown | undefined>();
  const [ended, setEnded] = useState<boolean>();

  /** Fired when play triggers an error */
  const handleOnError = (event?: Event) => {
    event?.preventDefault();
    setError(event);
  };

  /**
   * Fired when the player has initial duration and dimension information
   * This is a good place do player.play()
   */
  const handleOnLoadedMetadata = () => {
    setError(null);
    player?.focus();
  };

  /** Fired when downloading starts */
  const handleOnLoadStart = () => {
    setError(null);
    setEnded(false);
  };

  /**
   * Fired when the end of the media resource is reached.
   * (currentTime == duration)
   * */
  const handOnEnded = () => {
    setEnded(true);
  };

  /**
   * Fired when player promise is retured.
   * @param success
   */
  const handleOnSuccess = (success: BrightcovePlayerOnSuccessProps) => {
    const player = success.ref;
    setPlayer(success.ref);
    player.on("ready", () => {
      player.autoplay(true);
      document
        .querySelectorAll(".vjs-playback-rate,.vjs-dock-shelf")
        .forEach((el) => el.remove());
    });
    player.on("loadstart", handleOnLoadStart);
    player.on("loadedmetadata", handleOnLoadedMetadata);
    player.on("error", handleOnError);
    player.one("ended", handOnEnded);
  };

  const retryVideo = () => {
    player?.catalog?.getVideo(videoId, function (error, video) {
      if (!error) {
        player?.show();
        player?.catalog?.load(video);
      }
    });
  };

  useInterval({
    cancel: !Boolean(error),
    interval: 5000,
    callback: retryVideo,
  });

  // Reset state when videoId changes
  useEffect(() => {
    setEnded(false);
    setError(false);
  }, [videoId]);

  return (
    <PlayerWrapper>
      <ReactPlayerLoader
        accountId={accountId}
        videoId={videoId}
        onFailure={handleOnError}
        onSuccess={handleOnSuccess}
        embedOptions={{
          responsive: "16:9",
          unminified: process.env.GATSBY_SENTRY_ENVIRONMENT !== "production",
        }}
        // http://docs.videojs.com/tutorial-options.html
        // A Video.js options object to pass during the player creation process.
        // Takes precedence over Brightcove player configuration.
        options={{
          muted: true,
          liveui: true,
          outoplay: "play",
          normalizeAutoplay: true,
        }}
      />
      {Boolean(error || ended) && (
        <img
          src={placeholder}
          className="video-placeholder"
          alt="placeholder"
        />
      )}
    </PlayerWrapper>
  );
};

export default BrightcoverPlayer;
