import React, { useContext } from "react";
import SidebarContext from "../../context/SidebarContext";

export type PanelProps = {
  title: string;
  children: any;
};

const SidebarContent: React.FC<PanelProps> = ({ title, children }) => {
  const {
    sidebarState,
    setSidebarState,
    openSidebar,
    setOpenSidebar,
    position,
  } = useContext(SidebarContext);

  const toggleSidebarState = (sidebarName: Record<string, string>): void => {
    // Duplicating the sidebar state and setting all the states to `false`
    const states = Object.assign({}, sidebarState);
    let newState = {};
    Object.keys(states).forEach((key) => (states[key] = false));
    if (sidebarName.title && sidebarName.title.length) {
      newState = {
        [sidebarName.title]: !sidebarState[sidebarName.title],
      };
    }
    const newStates = {
      ...states,
      ...newState,
    };
    if (Object.values(newStates).some((v) => v)) {
      const openSidebar = Object.keys(newStates).filter((k) => newStates[k])[0];
      setOpenSidebar(openSidebar);
    } else {
      setOpenSidebar("");
    }

    setSidebarState(newStates);
  };

  const stopPropagation = (e: unknown) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`sidebarTab  ${position === "left" ? "isLeft" : ""} ${
        sidebarState[title] ? "active" : "blank"
      }`}
      onClick={() => toggleSidebarState({ title })}
    >
      <div className="sidebarArrow"></div>
      <div className="panelText">{title}</div>
      <div
        className={`${sidebarState[title] ? "active" : "hidden"} ${position}`}
        onClick={stopPropagation}
      >
        <div onClick={stopPropagation} style={{height: "100%", display: "inline-flex", width: "100%"}}>{children}</div>
      </div>
    </div>
  );
};

export default SidebarContent;
