import { useLocation } from "@reach/router";
import { graphql, StaticQuery } from "gatsby";
import React, { FunctionComponent, useEffect, useState } from "react";
import BrightcoverPlayer from "../../components/BrightcovePlayer";
import * as StyledPlayer from "../../components/BrightcovePlayer/styled";
import SurveySideBar from "../../components/interaction/surveySideBar";
import WallsIoTwitterWall from "../../components/interaction/wallsIoTwitterWall";
import { LiveProps, pageContextProps } from "../../components/interfaces";
import Layout from "../../components/layout";
import MultiDayAgenda from "../../components/multidayagenda";
import SEO from "../../components/seo";
import { default as SBar } from "../../components/sidebar";
import SidebarContent from "../../components/sidebar/sidebarcontent";
import { StyledLiveEventWrapper } from "../../components/styled";
import { VideoPlaylist } from "../../components/videoPlayer";
import useAuthModal from "../../hooks/useAuthModal";
import { trackMetric, trackPageview } from "../../services";
import { isBrowser, mergeWithInitialQuery } from "../../utils";

const Live: FunctionComponent<LiveProps> = ({
  data,
  pageContext: { videoId },
}) => {
  const location = useLocation();
  const { site } = data;
  const { defaultTabs, videoPlayerSetting, singlePage } = site.siteMetadata;
  const { liveMainVideoId, accoundId, playerId } = videoPlayerSetting;
  const { user } = useAuthModal({ authRequired: true });
  const [nowPlayingVideoId, setNowPlayingVideoId] = useState(
    videoId || liveMainVideoId
  );
  const handleOnLiveSessionVideoClick = (video: { videoId: string }) => {
    const { videoId } = video;
    isBrowser && window.scrollTo(0, 0);
    setNowPlayingVideoId(videoId);
    trackMetric({
      metricType: "pageview",
      payload: {
        type: "sessionVideoClick",
        videoId,
      },
    });
  };

  useEffect(() => {
    const query = mergeWithInitialQuery(location.search);
    const url = `${location.pathname}?${query}`;
    trackPageview(url);
  }, []);

  return (
    <Layout
      footerClassName="black"
      menu={singlePage ? "single" : ""}
      className="page__watch__live"
    >
      <StyledLiveEventWrapper>
        {user && (
          <StyledPlayer.CenterStage>
            <div className="video-top">
              <img
                src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/it-modernization-summit/2023/live/ITModSummit.png"
                alt="IT Modernization & AI Summit"
                className="CMSLockImage"
              />
              <img
                src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/cybersecurity-modernization-summit/2023/StateScoop_EdScoop_Black.png"
                alt="STATESCOOP | EDSCOOP"
                className="SSESimage"
              />
            </div>
            <div className="video-top-mobile">
              <img
                src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/it-modernization-summit/2023/live/ITModSummit.png"
                alt="Fortinet Federal"
              />
            </div>
            <BrightcoverPlayer
              accountId={accoundId}
              playerId={playerId}
              videoId={nowPlayingVideoId}
            />
            <div className="video-bottom">
              <img
                src="https://sng-client-assets.s3.amazonaws.com/fedscoop/upgather/fortinet/live/logo_FEDSCOOP-STATESCOOP.png"
                alt="produced by fedscoop and statescoop"
              />
            </div>
            <Sidebar />
          </StyledPlayer.CenterStage>
        )}
        <MultiDayAgenda activeTab={defaultTabs.live} />
        <h1 className="titlecheck">CHECK OUT TODAY'S SESSIONS</h1>
        <VideoPlaylist onClick={handleOnLiveSessionVideoClick} pageContext />
      </StyledLiveEventWrapper>
    </Layout>
  );
};

const Sidebar = () => (
  <SBar>
    <SidebarContent title="Social Wall">
      <WallsIoTwitterWall />
    </SidebarContent>
    {/* <SidebarContent title="Survey">
      <SurveySideBar />
    </SidebarContent> */}
  </SBar>
);

export default ({ pageContext }: { pageContext: pageContextProps }) => (
  <StaticQuery
    query={graphql`
      query LivePageVirtualQuery {
        site {
          siteMetadata {
            singlePage
            defaultTabs {
              live
            }
            videoPlayerSetting {
              accoundId
              playerId
              liveMainVideoId
              liveDVRVideoId
              liveSessionPlaylistId
              placeholders {
                activeAt
                inactiveAt
                uri
                enable
              }
            }
          }
        }
      }
    `}
    render={(data) => <Live data={data} pageContext={pageContext} />}
  />
);

export const Head = () => {
  return (
    <SEO title="Live Event">
      <script type="application/ld+json">{JSON.stringify({})}</script>
    </SEO>
  );
};
