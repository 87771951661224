import { graphql, Script, useStaticQuery } from "gatsby";
import React, { CSSProperties, FC } from "react";
import { StyledTwitterSideBar } from "../styled";

export interface WallsIoProps {
  style?: CSSProperties;
}

export const WallsIoTwitterWall: FC<WallsIoProps> = ({ style }) => {
  const {
    site: {
      siteMetadata: { twitterWallSlug },
    },
  } = useStaticQuery(graphql`
    query WallsIoTwitterWallQuery {
      site {
        siteMetadata {
          twitterWallSlug
        }
      }
    }
  `);

  return (
    <StyledTwitterSideBar>
      <div className={`tweetContain`} style={style}>
        <Script
          src="https://walls.io/js/wallsio-widget-1.2.js"
          onLoad={() => {
            document.getElementsByClassName(
              `tweetContain`
            )[0].innerHTML = `<iframe
            allowFullScreen="true"
            id="wallsio-iframe"
            src=https://my.walls.io/${twitterWallSlug}?nobackground=1&show_header=0
            style="border: 0; height: 100%; width: 100%;"
            loading="lazy"
            title="My social wall"
          />`;
          }}
          onError={() =>
            console.log("Error loading Twitter walls widget script")
          }
        />
      </div>
    </StyledTwitterSideBar>
  );
};

export default WallsIoTwitterWall;
