import React from "react";
import styled from "styled-components";

export const StyledTwitterSideBar = styled.div`
  max-width: 400px;
  height: auto;
  margin: 0 auto;
  overflow-x: hidden;
  white-space: nowrap;
  overflow-y: hidden;
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 340px 1fr;

  @media only screen and (max-width: 640px) {
    grid-template-columns: unset;
    max-width: 100%;
    margin-bottom: 0px;
    grid-template-columns: 1fr;
    padding-bottom: 30px;
    overflow-y: hidden;
    width: 98%;
  }

  .tweetAvatar img {
    border-radius: 50%;
    border: 1px solid #8733ff;
  }
  .tweetContain {
    overflow-y: hidden;
    background: #e2e2e2;
  }

  @media screen and (max-width: 640px) {
    .tweetContain {
      height: 745px;
    }
  }

  .tweetContainer {
    display: grid;
    grid-template-columns: 1fr;
    white-space: normal;
    box-sizing: border-box;
    margin-right: 10px;
    margin-left: 10px;
    padding: 5px;
  }
  .tweetContainer.active {
    display: block;
  }

  .retweet .tweet {
    margin-top: 5px;
  }

  .tweet {
    background-color: #fff;
    border-radius: 0px;
    padding: 5px;
    border: 1px solid silver;

    display: grid;
    grid-template-columns: 60px 1fr;
  }

  .tweetMedia {
    margin-top: 5px;
    width: 100%;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    border-radius: 10px;
    background-color: red;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .retweet .tweetBlock {
    max-width: 224px;
  }
  .retweet .tweetBlock .tweet {
    max-width: 220px;
    grid-template-columns: 40px 1fr;
  }
  .retweet .tweetBlock .tweet img {
    width: 30px;
  }
`;
