import React from "react";
import styled from "styled-components";

export const StyledLiveEventWrapper = styled.div`
  background-color: #fff;
  color: #fff;

  h1.titlecheck {
    background-color: #e71319;
    font-family: var(--main-font) !important;
    font-weight: 700 !important;
    font-size: 80px !important;
    line-height: 117px;
    text-align: center !important;
    margin-left: 0px !important;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0;
    margin-left: 115px;
    margin-bottom: -70px;
    padding-block: 85px;
  }

  .sessionWrap {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 1fr 1fr 1fr;

    .blocks {
      border: 1px solid #000;

      button {
        color: #fff;
        outline: none;
        border: none;
        padding: 0px;
        margin: 0px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: block;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .vidTitle {
        position: relative;
        bottom: 35px;
        text-align: center;
        width: 100%;
        border: none;
        /*margin-bottom: -22px;*/
        font-family: "Metropolis", sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
        @media only screen and (min-width: 1441px) {
          font-size: 20px;
          /*margin-bottom: -25px*/
        }
      }
    }
    .blocks.on-demand img {
      filter: brightness(0.3);
    }
  }

  .videoWrap {
    padding-top: 40px;
    padding-bottom: 20px;
    margin-bottom: 0px;
    background-color: #fff;
    color: #000;
    .container {
      display: grid;
      grid-template-columns: 0.3fr 1fr;
      grid-column-gap: 90px;
      margin: 0 auto;
      margin-left: 115px;
      margin-right: 125px;
      max-width: 1200px;
      .DateBanner {
        text-align: left;
        font-size: 36px;
        margin-top: 10px;
        .daynum {
          font-family: "Metropolis-SemiBold", sans-serif;
        }
        .divbreak {
          color: #ccc;
        }
        .specdate {
        }
        .imageDesk {
          margin-top: 30px;
          width: 100%;
        }
        .imageMobile {
          display: none;
        }
      }
      .video {
        img {
          width: 100%;
        }
      }
    }
  }
  .toggleAgenda {
    background-color: #8733ff;
    color: #000;
    font-size: 20px;
    text-align: right;
    .container {
      text-align: center;
      font-family: "Metropolis-SemiBold", sans-serif;
      font-size: 36px;
      max-width: 980px;
      margin: 0 auto;
      padding-block: 23.7px;
      .followtxt {
        display: inline-block;
        font-weight: 800 !important;
        vertical-align: middle;
      }
      a.tButton {
        height: 50px;
        width: 280px;
        background-color: #fff;
        color: #8733ff;
        text-align: center;
        margin-left: 45px;
        font-family: "sofia-pro", sans-serif;
        font-size: 20px;
        letter-spacing: 2px;
        border-radius: 0px;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        .blockleft {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .blockleft,
        .caretblock {
          display: inline-block;
          width: 80%;
          vertical-align: middle;
        }
        .caretblock {
          border-left: 1px solid #8733ff;
          width: 15%;
          padding-left: 5px;
          font-size: 16px;
          img {
            margin-right: 4px;
            margin-bottom: 2px;
          }
        }
      }
      a.tButton:hover {
        border: 1px solid #fff;
        background-color: #000;
        color: #fff;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    .DateBanner {
      font-size: 30px !important;
    }
    .toggleAgenda {
      .container {
        max-width: 680px;
        margin-block: 10px !important;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    h1.titlecheck {
      padding: 60px 20px 100px 20px;
      font-size: 52px !important;
      letter-spacing: 0.5px;
    }
    .toggleAgenda {
      .container {
        max-width: 95%;
        text-align: center;
        a.tButton {
          margin: 0 auto;
          margin-top: 30px;
        }
      }
    }
    .sessionWrap {
      grid-template-columns: 1fr 1fr;
    }
    .videoWrap {
      padding-bottom: 50px;
      padding-top: 50px;
      .container {
        grid-template-columns: 1fr;
        margin: 0 auto;
        width: 95%;
        .DateBanner {
          margin-left: 30px;
          margin-right: 30px;
          .imageDesk {
            display: none;
          }
          .imageMobile {
            display: block;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`;
