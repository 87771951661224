import styled from "styled-components";

export const PlayerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  position: relative;
  max-width: 960px;
  width: 70vw;
  height: auto;
  overflow: hidden;
  margin: auto;
  padding: 50px;

  .brightcove-react-player-loader {
    display: block;
    grid-area: 1 / 1 / 2 / 2;
    aspect-ratio: 16 / 9;
  }

  .video-placeholder {
    display: block;
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    z-index: 9999;
    aspect-ratio: 16 / 9;
  }

  video-js {
    display: block;
    padding-top: 56%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .vjs-big-play-button {
    background: white;
  }

  .vjs-big-play-button .vjs-icon-placeholder {
    color: #e71319;
  }

  .vjs-control-bar {
    background: white;
    color: #e71319;
  }

  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 2.8em;
    line-height: 1.2;
  }

  .pictureInPictureToggleLabel,
  .pictureInPictureToggleButton,
  .vjs-picture-in-picture-control,
  .vjs-control-bar .vjs-seek-to-live-control,
  .vjs-control-bar .vjs-picture-in-picture-control,
  .vjs-control-bar .vjs-live-display {
    display: none;
  }

  .vjs-control-bar .branding {
    color: white;
    text-transform: uppercase;
    background: #e71319;
    cursor: pointer;
    display: block;
  }

  .vjs-control-bar .branding.live {
    display: none;
  }

  .vjs-dock-text {
    background: linear-gradient(180deg, #00000073, transparent);

    .vjs-dock-description,
    .vjs-dock-title {
      color: white;
    }
  }

  .vjs-dock-text,
  .vjs-custom-endscreen-overlay {
    display: none;
  }
`;

export const CenterStage = styled.div`
  background: #fff;
  position: relative;

  @media screen and (min-width: 641px) {
    height: 850px;
  }
`;
