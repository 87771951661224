import { createContext } from "react";

export const defaultState={
  sidebarState:{},
  setSidebarState: ()=> undefined,
  openSidebar:"",
  setOpenSidebar: ()=> undefined,
  position:"left",
}

export interface ISidebarContext{
  sidebarState:Record<string,string>;
  setSidebarState: (state:Record<string,string>) => void;
  openSidebar:string,
  setOpenSidebar: (state:string) => void;
  position:string;
}
const SidebarContext = createContext<ISidebarContext>(defaultState);
SidebarContext.displayName = "SidebarContext";

export default SidebarContext;
export const { Provider: SidebarProvider, Consumer: SidebarConsumer } =
  SidebarContext;
