import { withPrefix } from "gatsby";
import React from "react";
import hoverComingSoonImg from "../../../static/icons/Hover_ComingSoon.png";
import hoverPlayImg from "../../../static/icons/Hover_Play.png";

const VideoTile = ({ video, index, isActivate, onClick, ...props }) => {
  const hoverComingSoon = hoverComingSoonImg;
  const hoverPlay = hoverPlayImg;

  const handleOnVideoClick = () => {
    isActivate && onClick && onClick(video);
  };

  return (
    <div
      key={index}
      data-video-id={video.videoId}
      className={`blocks ${!isActivate ? "inactive" : ""}`}
    >
      <button onClick={handleOnVideoClick}>
        <div className="csoverlay">
          <img
            className="play"
            src={isActivate ? hoverPlay : hoverComingSoon}
            style={{ cursor: isActivate ? "pointer" : "not-allowed" }}
          />
        </div>
        <img src={withPrefix(video.image)} alt={video.buzzWord} />
        <div className="vidTitle">{video.buzzWord}</div>
      </button>
    </div>
  );
};

export default VideoTile;
