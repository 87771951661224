import React, { useState } from "react";
import SidebarContext from "../../context/SidebarContext";

export type sidebarState = {
  [index: string]: boolean;
};
export type tabIcons = {
  open: string;
  close: string;
};
export type styles = {
  background?: string;
  color?: string;
  borderColor?: string;
};
export type position = "right" | "left";
export type sidebarOpen = () => boolean;

export type SidebarProps = {
  position?: position;
  children: any;
};

const Sidebar: React.FC<SidebarProps> = (props) => {
  const [sidebarState, setSidebarState] = useState<
    sidebarState
  >({ "Twitter Wall": false, survey: false, gift: false });
  const [openSidebar, setOpenSidebar] = useState<string>("");
  const position: "left" | "right" = props.position ? props.position : "right";

  const allSidebarData = {
    sidebarState,
    setSidebarState,
    openSidebar,
    setOpenSidebar,
    position,
  };
  
  return (
    <SidebarContext.Provider value={allSidebarData}>
      <div
        className={`panelButtonContainer sidebarPanelContainer ${`sidebarGrid`+props.children.length} ${openSidebar} ${
          position === "left" ? "isLeft" : ""
        }`}
      >
        {props.children}
      </div>
    </SidebarContext.Provider>
  );
};

export default Sidebar;
