import { graphql, StaticQuery } from "gatsby";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import useEventDetails from "../../hooks/useEventDetails";
import useInterval from "../../hooks/useInterval";
import { getPlaylist } from "../../services";
import { BrightcoveVideo } from "../../services/api";
import MultiDayTab from "../multiDayTab";
import VideoTile from "./videoTile";

interface VideoPlaylistProps {
  onClick?: (video: BrightcoveVideo) => void;
  className?: string;
}

const VideoPlaylist: FunctionComponent<VideoPlaylistProps> = ({
  data,
  onClick,
  className = "sessionWrap",
}) => {
  const { allSessionsJson, site } = data;
  const {
    videoPlayerSetting: { liveSessionPlaylistId },
  } = site.siteMetadata;
  const { agendaSessions } = allSessionsJson;

  const sessionVideos = [
    ...agendaSessions
      .filter(({ tile }) => tile)
      .map((session) => {
        return session;
      }),
  ];
  const { usDateToMachineDate } = useEventDetails();
  const videos = sessionVideos;
  let dates = videos.map((video) => video.date).filter(Boolean);

  dates = [...new Set(dates)];
  const formattedDates = dates.map((date) => usDateToMachineDate(date));

  // TODO: PLAYLIST FETCHING SHOULD BE CACHED W/ SWR 🦺
  const [activeIds, setActiveVideoIds] = useState<string[]>();

  const fetchPlaylist = async () => {
    const response = await getPlaylist(liveSessionPlaylistId);
    const currentTime = new Date();
    const activeVideoIds = response.data
      .filter((video) => !video.schedule || new Date(video.schedule.starts_at) <= currentTime
      )
      .map((video) => video.id);
      setActiveVideoIds(activeVideoIds);
  };

  useInterval({
    interval: 10000,
    callback: fetchPlaylist,
  });

  return (
    <MultiDayTab
      dates={formattedDates}
      activeTab={0}
      className="videos"
      darkMode={true}
      singleDayMode={true}
    >
      {formattedDates.map((currentDate, index) => {
        const filteredVideos = videos.filter((video) =>
          dates.includes(video.date)
        );
        return (
          <>
            <div className="videos tabContent">
              {formattedDates.length > 1 && (
                <div className="fulldate">
                  <span className="dayNum">Day {index + 1}:</span>{" "}
                  {currentDate.toLocaleString("en-US", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                  })}
                </div>
              )}
              <div className={className}>
                {filteredVideos.map((video: BrightcoveVideo, index) => {
                  return (
                    <VideoTile
                      key={index}
                      video={video.tile}
                      index={index}
                      isActivate={activeIds?.includes(video.tile.videoId)}
                      onClick={onClick}
                    />
                  );
                })}
              </div>
            </div>
          </>
        );
      })}
    </MultiDayTab>
  );
};

export default ({ pageContext, onClick }) => (
  <StaticQuery
    query={graphql`
      query LivePageVirtualQuery2 {
        allSpeakersJson {
          agendaSpeakers: nodes {
            image
            name
            title
          }
        }
        allSessionsJson {
          agendaSessions: nodes {
            date
            startTime
            endTime
            title
            speakers {
              isModerator
              name
            }
            tile {
              buzzWord
              image
              slug
              videoId
            }
          }
        }
        site {
          siteMetadata {
            singlePage
            eventStartTime
            eventEndTime
            defaultTabs {
              live
            }
            videoPlayerSetting {
              accoundId
              playerId
              liveMainVideoId
              liveDVRVideoId
              liveSessionPlaylistId
              placeholders {
                activeAt
                inactiveAt
                uri
                enable
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <VideoPlaylist data={data} pageContext={pageContext} onClick={onClick} />
    )}
  />
);
